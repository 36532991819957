<template>
  <div class="p-6 pt-10 md:p-8 md:pt-12 flex items-center justify-between">
    <span class="md:text-3xl text-xl text-dark-800 font-bold md:font-medium"
      >Company Assets List</span
    >
    <easiButton
      v-if="!fromArchived"
      @click="showAdd = true"
      variant="outlined"
      size="small"
      color="primary"
      class="flex items-center gap-2 border-primary mt-2 md:mt-0 rounded-lg"
    >
      <img class="w-3" src="@/assets/icons/plus.svg" alt="" />
      <span class="text-sm">Add Asset</span>
    </easiButton>
  </div>

  <hr class="text-dark-100" />

  <div v-if="assets && assets.length" class="grid gap-4 grid-cols-12">
    <div
      style="height: 500px"
      class="p-6 md:p-8 col-span-12 md:col-span-7 flex flex-col gap-6 overflow-y-auto"
    >
      <div
        v-for="(item, i) in assets"
        :key="i"
        @click="selectAsset(i)"
        :class="
          selected === i
            ? 'p-4 border border-dark-300 bg-background-50 rounded-xl'
            : 'border-b border-dark-100'
        "
        class="grid gap-2 grid-cols-12 last:border-b-0 pb-6 cursor-pointer"
      >
        <div class="flex flex-col col-span-12">
          <!-- ////// Image ///// -->
          <div
            class="bg-background-50 overflow-hidden w-full h-48 mb-4 col-span-12 md:hidden flex justify-center items-center w-full"
          >
            <img
              v-if="item.imageLink"
              @click="onPreview(item.imageLink)"
              :src="item.imageLink"
              class="object-cover h-full cursor-pointer"
              alt=""
            />
            <div v-else class="text-center">No image</div>
          </div>
          <div class="flex flex-col gap-4">
            <div class="flex items-center justify-between">
              <div class="flex flex-col">
                <span class="text-sm text-dark-500">Asset</span>
                <span class="text-base text-dark-800 font-medium">{{
                  item.assetName
                }}</span>
              </div>

              <easiButton
                v-if="route.path.includes('edit')"
                @click="updateId(item)"
                size="small"
                variant="outlined"
                color="primary"
                class="py-1"
              >
                <span class="flex items-center mr-2 text-base gap-3">
                  <img
                    class="w-4"
                    src="@/assets/icons/edit-primary.svg"
                    alt=""
                  />

                  Edit
                </span>
              </easiButton>
            </div>

            <div class="flex flex-col">
              <span class="text-sm text-dark-500">Description</span>
              <span class="text-base text-dark-800 font-medium">{{
                display(item.description)
              }}</span>
            </div>
            <div class="flex flex-col">
              <span class="text-sm text-dark-500">Date Received</span>
              <span class="text-base text-dark-800 font-medium">{{
                display(item.dateReceived)
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :class="!selected ? 'bg-background-50' : ''"
      class="hidden p-4 col-span-5 md:flex items-center relative justify-center w-full"
      @click="onPreview(convertToHttpsUrl(preview))"
    >
      <div class="w-full h-full top-0 absolute opacity-20 hover:bg-black"></div>
      <img
        v-if="preview.length && checkFileType(preview) === 'image'"
        :src="preview"
        class="object-contain h-auto cursor-pointer"
        alt=""
      />
      <iframe
        v-else-if="preview.length && checkFileType(preview) === 'doc'"
        :src="convertToHttpsUrl(preview)"
        class="object-contain w-full h-full border-0 h-60 cursor-pointer"
        style="border: none"
      ></iframe>
      <div v-else class="text-center">No image</div>
    </div>
  </div>

  <div v-else class="my-8">
    <easiEmptyState text="No Company Assets" />
  </div>

  <easiModal
    :isBorder="false"
    :is="false"
    v-if="showAdd"
    @close="showAdd = false"
  >
    <AssetAdd type="add" />
  </easiModal>

  <easiImageView
    :visible="visibleRef"
    :imgs="imgsRef"
    @hide="onHide"
  ></easiImageView>

  <easiModal
    :lite="true"
    :isBorder="false"
    :compliance="true"
    v-if="previewModal"
    @close="previewModal = false"
  >
    <div
      style="height: calc(100vh - 250px)"
      class="w-full flex flex-col items-center h-auto overflow-y-scoll"
    >
      <iframe
        ref="pdfPreview"
        :src="imgsRef"
        style="width: 98%; height: 100%"
      ></iframe>
    </div>
  </easiModal>
</template>

<script setup>
import { reactive, ref, computed, onMounted } from "vue";
import AssetAdd from "@/components/Employee/Edit/Asset/Asset.vue";
import { useDataStore } from "@/stores/data.js";
import { useRoute } from "vue-router";

const store = useDataStore();
const route = useRoute();

const props = defineProps({
  type: {
    type: String,
    default: "view",
  },
});
const emit = defineEmits(["asset"]);

const previewModal = ref(false);
const showAdd = ref(false);
const visibleRef = ref(false);
const imgsRef = ref("");

let preview = ref("");
let selected = ref(0);

const fromArchived = computed(() => store.getViewFromArchived);

const employee = computed(() =>
  fromArchived.value
    ? store.getArchivedEmployeeById(route.params.id)
    : store.getEmployeeById(route.params.id)
);

const assets = computed(() => employee && employee.value.companyAsset);

// console.log(assets.value);

onMounted(() => {
  selectAsset(selected.value);
});

const selectAsset = (index) => {
  // console.log(assets.value[index].imageLink, "INND", index);
  preview.value =
    assets.value && assets.value[index] && assets.value[index].imageLink;
  selected.value = index;
};

function convertToHttpsUrl(url) {
  console.log(url);
  if (url && typeof url === "string" && !url.startsWith("https://")) {
    url = url.replace("http://", "https://");
  }
  return url;
}

function checkFileType(url) {
  if (url && typeof url === "string") {
    var extension = url.split(".").pop().toLowerCase();

    if (
      extension === "pdf" ||
      extension === "docx" ||
      extension === "txt" ||
      extension === "doc"
    ) {
      return "doc";
    } else if (
      extension === "jpg" ||
      extension === "jpeg" ||
      extension === "png" ||
      extension === "gif"
    ) {
      return "image";
    } else {
      return "The link is not a PDF document or image.";
    }
  }
}

const onPreview = (img) => {
  console.log(img);
  imgsRef.value = img;

  if (checkFileType(img) === "doc") {
    previewModal.value = true;
  } else {
    visibleRef.value = true;
  }
};

const onHide = () => (visibleRef.value = false);

function updateId(obj) {
  emit("asset", obj);
}

const display = (arg) => {
  return arg ? arg : "N/A";
};
</script>

<style></style>
